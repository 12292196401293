body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.mui-form fieldset fieldset legend {
  font-size: 1rem;
}

.mui-form fieldset fieldset label {
  font-size: .875rem;
}

.mui-form fieldset label {
  font-size: 1rem;
}

a.nav-link {
  color:'#02487b';
  font-weight: bold;
  text-decoration: none;
}

a.nav-link:focus, a.nav-link:hover {
  color: '#488aff';
  text-decoration: underline;
}

.ql-editor {
  min-height: 150px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.ql-editor p, h1, h2, h3 {
  margin-bottom: 1rem !important;
}

b, strong {
  color: #000000;
  font-weight: 900 !important;
}

.rw-dropdown-list .rw-select {
  display: none;
}

.rw-dropdown-list .rw-placeholder {
  width: 100%
}

.rw-dropdown-list .rw-input {
  display: block;
  height: 32px;
  max-width: 90%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.26);
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
  color: rgba(0,0,0,.87);
  font-size: 16px;
  font-family: inherit;
  line-height: inherit;
  padding: 0 25px 0 0; 
}

.rw-dropdown-list .rw-widget-input {
  display: block;
  height: 32px;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.26);
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNiIgd2lkdGg9IjEwIj48cG9seWdvbiBwb2ludHM9IjAsMCAxMCwwIDUsNiIgc3R5bGU9ImZpbGw6cmdiYSgwLDAsMCwuMjQpOyIvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-position: right center;
  cursor: pointer;
  color: rgba(0,0,0,.87);
  font-size: 16px;
  font-family: inherit;
  line-height: inherit;
  padding: 0 25px 0 0; 
}

.rw-datetime-picker .rw-widget-container {
  display: inline-table;
  height: 32px;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.26);
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: white;
  cursor: pointer;
  color: rgba(0,0,0,.87);
  font-size: 16px;
  font-family: inherit;
  line-height: inherit;
}

.rw-datetime-picker .rw-widget-input {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.rw-state-focus .rw-widget-container, .rw-state-focus .rw-widget-container:hover {
  outline: 0;
  height: calc(32px + 1px);
  margin-bottom: -1px;
  border-color: #2196F3;
  border-width: 2px;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.text-button {
  background: none !important;
  border: none; 
  padding: 0 !important;
  color: #4BBF73;
  text-decoration: underline;
  cursor: pointer;
}

.rbc-timeslot-group {
  min-height: 60px !important;
}

/* .rbc-time-column {
  min-width: 100px;
}
*/
/* .rbc-header {
  min-width: 100px !important;
}

.rbc-day-slot {
  min-width: 100px !important;
}

.rbc-time-view {
  overflow-wrap: normal !important;
  overflow: scroll;
}
.rbc-current-time-indicator {
  width: 100% !important;
}
.rbc-time-header {
  min-width: 1579px !important;
}

.rbc-time-content {
  overflow: visible !important;
  min-width: 1579px !important;
} */

.alert-warning { 
  color: #7d5a29 !important;
}
